<template>
  <v-container id="identities-list" tag="section" fluid grid-list-md>
    <base-material-card icon="mdi-bank-transfer" :title="$t('identities.list')">
      <v-data-table
        :headers="headers"
        :items="items"
        mobile-breakpoint="500"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        sort-by="created_at"
        sort-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-select
              v-if="!$route.params.user_id"
              v-model="user_id"
              class="search-field"
              item-value="id"
              :label="$t('identities.user')"
              hide-details
              :items="forSelect(meta.users)"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="client_id"
              class="search-field"
              item-value="id"
              :label="$t('identities.client_id')"
              hide-details
              :items="forSelect(meta.clients)"
              @change="changeOutput"
            ></v-select>
            <v-text-field
              @change="changeOutput"
              class="search-field"
              v-model="search"
              append-icon="search"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-select
              v-model="status"
              class="search-field"
              item-value="id"
              :label="$t('identities.status')"
              hide-details
              :items="statuses"
              @change="changeOutput"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ moment(item.created_at).format("lll") }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ $t("identities.statuses." + item.status.toUpperCase()) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="canEditCase(item)"
            small
            class="mr-2"
            @click="onEdit(item)"
            >mdi-pencil</v-icon
          >
          <v-icon v-else small class="mr-2" @click="viewDetails(item)"
            >mdi-eye</v-icon
          >
        </template>
      </v-data-table>
      <details-dialog ref="detailsDialog" title="Details"></details-dialog>
      <identity-form
        v-if="meta"
        @itemSaved="onSaved"
        :countries="forSelect(meta.countries, 0)"
        ref="editForm"
      ></identity-form>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudTable";
import DetailsDialog from "@/components/crud/DetailsDialog";
import IdentityForm from "./IdentityForm";

export default {
  mixins: [crudMixin],
  components: { DetailsDialog, IdentityForm },
  data() {
    return {
      search: "",
      apiQuery: "/kyc/identities/",
      headers: [
        { text: this.$t("identities.user"), align: "start", value: "user" },
        { text: this.$t("identities.client_id"), value: "client" },
        { text: this.$t("identities.time"), value: "created_at" },
        {
          text: this.$t("identities.case_id"),
          value: "case_id",
        },
        { text: this.$t("identities.status"), value: "status" },
        {
          text: this.$t("identities.Actions"),
          value: "actions",
          sortable: false,
        },
      ],
      status: "ALL",
      statuses: [
        {
          id: "ALL",
          text: this.$t("identities.statuses.ALL"),
        },
        {
          id: "COMPLETED",
          text: this.$t("identities.statuses.COMPLETED"),
        },
        {
          id: "PENDING",
          text: this.$t("identities.statuses.PENDING"),
        },
        {
          id: "REQUESTED",
          text: this.$t("identities.statuses.REQUESTED"),
        },
        {
          id: "DOCUMENTS",
          text: this.$t("identities.statuses.DOCUMENTS"),
        },
        {
          id: "SELFIE",
          text: this.$t("identities.statuses.SELFIE"),
        },
        {
          id: "FAILED",
          text: this.$t("identities.statuses.FAILED"),
        },
      ],
      loading: true,
      client_id: null,
      clients: [],
      user_id: null,
      users: [],
    };
  },
  mounted() {
    this.user_id = this.options.user_id = this.$route.params.user_id
      ? this.$route.params.user_id
      : null;
    this.get(
      "/kyc/identities/meta?" +
        (this.options.user_id ? "user_id=" + this.options.user_id : "")
    ).then((data) => {
      this.meta = data;
    });
    if (this.$route.query.status) {
      this.status = this.$route.query.status.toUpperCase();
      this.$set(this.options, "status", this.status);
    }
  },
  methods: {
    changeOutput() {
      this.$set(this.options, "search", this.search);
      this.$set(this.options, "status", this.status);
      this.$set(this.options, "client_id", this.client_id);
      this.$set(this.options, "user_id", this.user_id);
      this.options.page = 1;
    },
    moment,
    viewDetails(item) {
      this.$refs.detailsDialog.viewDetails("/kyc/identities/" + item.id);
    },
    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item));
    },
    canEditCase(item) {
      return item.status == "PENDING";
    },
  },
};
</script>
<style scoped>
.search-field {
  margin-right: 10px;
}
.break-style {
  word-break: break-word;
  max-width: 7.5rem;
}
</style>
