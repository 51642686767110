<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="50vw"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("identities.edit identity") }}</span>
      </v-card-title>
      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container v-if="form && form.details">
            <v-row>
              <template v-for="(file, type) in form.details.files">
                <v-col
                  :key="'f' + type"
                  cols="12"
                  md="6"
                  class="py-0"
                  v-if="type == 'video'"
                >
                  {{ $t("identities.file." + type) }}
                  <video
                    width="100%"
                    controls
                    autoplay
                    :id="'file_' + type"
                  ></video>
                  <h4>Code: {{ form.details.code }}</h4>
                </v-col>
              </template>
              <template v-for="(file, type) in form.details.files">
                <v-col
                  :key="'f' + type"
                  cols="12"
                  md="6"
                  class="py-0"
                  v-if="type != 'video'"
                >
                  {{ $t("identities.file." + type) }}
                  <br />
                  <img width="100%" :id="'file_' + type" />
                </v-col>
              </template>

              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.fname"
                  :label="$t('identities.fname')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.lname"
                  :label="$t('identities.lname')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.email"
                  :label="$t('identities.email')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.phone"
                  :label="$t('identities.phone')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-phone"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.document_type"
                  :label="$t('identities.document_type')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-card-account-details"
                  disabled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.document_id"
                  :label="$t('identities.document_id')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-card-account-details"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-menu
                  ref="menu_bd"
                  v-model="menu_bd"
                  :close-on-content-click="false"
                  :return-value.sync="form.details.person.birthdate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form.details.person.birthdate"
                      :label="$t('identities.birthdate')"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.details.person.birthdate"
                    no-title
                    scrollable
                    :locale="$i18n.locale"
                    :first-day-of-week="1"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu_bd = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_bd.save(form.details.person.birthdate)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="form.details.person.gender"
                  :items="genders"
                  item-text="text"
                  item-value="id"
                  :label="$t('identities.gender')"
                  prepend-icon="mdi-account-question-outline"
                ></v-select>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.address"
                  :label="$t('identities.address')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-home-outline"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.zipcode"
                  :label="$t('identities.zipcode')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-map-marker-radius"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-text-field
                  v-model="form.details.person.city"
                  :label="$t('identities.city')"
                  :rules="[rules.server]"
                  prepend-icon="mdi-city"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="py-0">
                <v-select
                  v-model="form.details.person.country"
                  :items="countries"
                  item-value="id"
                  :label="$t('identities.country')"
                  prepend-icon="mdi-earth"
                ></v-select>
              </v-col>

              <v-col cols="12" class="d-sm-flex">
                <label>{{ $t("identities.status") }}</label>
                <v-checkbox
                  v-for="(status, idx) in statuses"
                  :key="'check' + idx"
                  v-model="form.status"
                  :label="status.text"
                  :value="status.id"
                  class="pe-2 py-2"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="form">
          <v-spacer></v-spacer>
          <v-btn color="gray" :disabled="sending" @click="closeForm">{{
            $t("Cancel")
          }}</v-btn>
          <v-btn type="submit" :disabled="sending" color="primary">{{
            $t("Save")
          }}</v-btn>
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0"
        >The case {{ savedItem.id }} was saved with success!</v-alert
      >
    </v-card>
  </v-dialog>
</template>

<script>
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {
    countries: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      apiQuery: "/kyc/identities/",
      rules: {
        server: () =>
          this.serverErrors && this.serverErrors.fname
            ? this.$t("banks.errors." + this.serverErrors.fname[0])
            : true,
      },
      statuses: [
        {
          id: "COMPLETED",
          text: this.$t("identities.statuses.COMPLETED"),
        },
        {
          id: "PENDING",
          text: this.$t("identities.statuses.PENDING"),
        },
        {
          id: "FAILED",
          text: this.$t("identities.statuses.FAILED"),
        },
      ],
      genders: [
        {
          id: "n",
          text: this.$t("identities.genders.n"),
        },
        {
          id: "m",
          text: this.$t("identities.genders.m"),
        },
        {
          id: "f",
          text: this.$t("identities.genders.f"),
        },
      ],
      menu_bd: false,
      files: [],
    };
  },
  methods: {
    editItem(item, index) {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
      this.editedItem = item;
      this.editedIndex = index;
      this.loading = true;
      this.form = null;
      this.get(this.apiQuery + item.id)
        .then((data) => {
          data.id = item.id;
          this.form = Object.assign({}, data);
          for (let i in this.form.details.files) {
            this.getFile("/kyc/files/" + item.id + "/" + i, "file_" + i);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = error.error || error.message;
          this.$emit("serverError", error);
          this.loading = false;
        });
      this.showFormDialog = true;
    },
  },
};
</script>
