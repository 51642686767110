var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"identities-list","tag":"section","fluid":"","grid-list-md":""}},[_c('base-material-card',{attrs:{"icon":"mdi-bank-transfer","title":_vm.$t('identities.list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"500","options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","sort-by":"created_at","sort-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 50],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[(!_vm.$route.params.user_id)?_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('identities.user'),"hide-details":"","items":_vm.forSelect(_vm.meta.users)},on:{"change":_vm.changeOutput},model:{value:(_vm.user_id),callback:function ($$v) {_vm.user_id=$$v},expression:"user_id"}}):_vm._e(),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('identities.client_id'),"hide-details":"","items":_vm.forSelect(_vm.meta.clients)},on:{"change":_vm.changeOutput},model:{value:(_vm.client_id),callback:function ($$v) {_vm.client_id=$$v},expression:"client_id"}}),_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"change":_vm.changeOutput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('identities.status'),"hide-details":"","items":_vm.statuses},on:{"change":_vm.changeOutput},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("lll"))+" ")]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t("identities.statuses." + item.status.toUpperCase()))+" ")]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.canEditCase(item))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-pencil")]):_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewDetails(item)}}},[_vm._v("mdi-eye")])]}}])}),_c('details-dialog',{ref:"detailsDialog",attrs:{"title":"Details"}}),(_vm.meta)?_c('identity-form',{ref:"editForm",attrs:{"countries":_vm.forSelect(_vm.meta.countries, 0)},on:{"itemSaved":_vm.onSaved}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }