<template>
  <v-dialog v-model="showDialog" max-width="30vw">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>

      <v-card-text>
        <slot v-bind="text"></slot>
        <table class="table table-striped">
          <tr v-for="(v, i) in details" :key="'tr' + i">
            <td>{{ i }}</td>
            <td>{{ v }}</td>
          </tr>
        </table>
      </v-card-text>

      <v-card-actions>
        <slot name="buttons">
          <v-spacer></v-spacer>
          <v-btn color="gray darken-1" @click="showDialog = false">Close</v-btn>
        </slot>
      </v-card-actions>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <slot :message="errorMessage" name="error">
        <v-alert v-if="errorMessage" tile class="mb-0" type="error">{{
          errorMessage
        }}</v-alert>
      </slot>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      title: this.$t("details"),
      showDialog: false,
      errorMessage: null,
      details: {},
      text: "",
    };
  },

  methods: {
    viewDetails(url, title) {
      if (title) {
        this.title = title;
      } else {
        this.title = this.$t("details");
      }
      this.sending = true;
      this.errorMessage = null;
      this.details = {};
      this.showDialog = true;
      this.get(url)
        .then((data) => {
          this.details = data;
          this.sending = false;
        })
        .catch((error) => {
          this.errorMessage = error.error || error.message;
          this.$emit("serverError", error);
          this.sending = false;
        });
    },
  },
};
</script>

<style scoped>
td {
  padding: 3px;
}
</style>
